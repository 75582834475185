<template>
  <div class="w-full ">
    <div class="w-full mb-4 page_box">
      <div class="px-2 pt-2 text-left text-gray-500 path">
        <navbar-text :navbarList="navbarList"></navbar-text>
      </div>
    </div>
    <div class="px-2 pb-4">
      <div class="flex justify-end py-2">
        <span>状态：</span><span class="text-primary"> {{ statusMap[orderInfo.status] }}</span>
      </div>
      <table class="w-full" style="text-align: left;">
        <tr v-for="item in dataList" :key="item.id">
          <td class="left_td" style="text-align: left;padding: .625rem .625rem;">
            {{ item.name }}
          </td>
          <td style="flex:1;text-align: left;padding: .625rem .625rem;">
            <div v-if="item.type != 2">
              {{
                item.fieldName == "modelId"
                ? orderInfo.modelName
                : orderInfo[item.fieldName]
              }}
            </div>
            <div class="flex flex-wrap" v-else>
              <div style="width:100px;height: 100px;" v-for="imgUrl in orderInfo[item.fieldName]" :key="imgUrl">
                <img :src="imgUrl" alt="" style="width: 95%;height: 95%;" @click="review(imgUrl)"
                  class="cursor-pointer" />
              </div>
            </div>
          </td>
        </tr>
      </table>

      <table class="w-full mt-8" style="text-align: left;">
        <tr>
          <td class="left_td" style="text-align: left;padding: .625rem .625rem;">
            状态
          </td>
          <td style="flex:1;text-align: left;padding: .625rem .625rem;">
            <div class="flex items-center justify-between w-full">
              <span> {{ statusMap[orderInfo.status] }}</span>
              <span class="pt-2 cursor-pointer text-primary" @click="goLogistics('merchant')" v-if="orderInfo.status > 5">
                查看物流
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td class="left_td" style="text-align: left;padding: .625rem .625rem;">
            工单号
          </td>
          <td style="flex:1;text-align: left;padding: .625rem .625rem;">
            {{ orderInfo.serviceOrderNumber }}
          </td>
        </tr>
        <tr v-if="orderInfo.status == 4">
          <td class="left_td" style="text-align: left;padding: .625rem .625rem;">
            物流公司
          </td>
          <td style="flex:1;text-align: left;padding: .625rem .625rem;">
            {{ orderInfo.company }}
          </td>
        </tr>
        <tr v-if="orderInfo.status > 3">
          <td class="left_td" style="text-align: left;padding: .625rem .625rem;">
            收货人信息
          </td>
          <td style="flex:1;text-align: left;padding: .625rem .625rem;" v-html="orderInfo.platformReceiving"></td>
        </tr>
        <tr v-if="orderInfo.status == 4">
          <td class="left_td" style="text-align: left;padding: .625rem .625rem;">
            我的物流信息
          </td>
          <td style="flex:1;text-align: left;padding: .625rem .625rem;">
            <div>
              <span>物流公司：{{ orderInfo.company }}</span>
              <span class="ml-4">物流单号：{{ orderInfo.shipmentNumber }}</span>
            </div>
            <div class="py-1">
              <span>收货人名称：{{ orderInfo.contactPerson }}</span>
              <span class="ml-4">收货人电话：{{ orderInfo.phone }}</span>
            </div>
            <div>收货地址：{{ orderInfo.address }}</div>
            <div class="pt-2 cursor-pointer text-primary" @click="goLogistics('user')">
              查看物流
            </div>
          </td>
        </tr>
        <tr>
          <td class="left_td" style="text-align: left;padding: .625rem .625rem;">
            维修详情
          </td>
          <td style="flex:1;text-align: left;padding: .625rem .625rem;">
            {{ orderInfo.remark }}
          </td>
        </tr>
      </table>

      <div class="flex justify-center mt-2 md:justify-start" v-if="
        orderInfo.status == 2 ||
        orderInfo.status == 3 ||
        orderInfo.status == 6
      ">
        <div class="w-1/2 py-2 text-white rounded cursor-pointer bg-primary order_btn md:w-1/4" @click="btnClick">
          {{
            orderInfo.status == 2
            ? "填写寄回物流单号"
            : orderInfo.status == 3
              ? "修改申请"
              : "确认收货"
          }}
        </div>
      </div>
    </div>
    <Popup :isShow="isShow" @close="closeSearch">
      <template>
        <div class="flex justify-center">
          <img :src="reviewImg" alt />
        </div>
      </template>
    </Popup>
  </div>
</template>

<script>
import Popup from "@/components/Popup/Popup";
import { orderStatusMap } from "@/utils/utils.js";
import { mapState } from "vuex";
export default {
  components: {
    Popup,
  },
  data() {
    return {
      dataList: [],
      id: "",
      orderInfo: {},
      statusMap: orderStatusMap,
      isShow: false,
      reviewImg: "",
      navbarList: [
        { title: "个人中心", path: "/index/personal" },
        { title: "保单查询", path: "/index/warranty/qurey" },
        { title: "工单详情", path: "" },
      ],
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  watch: {},
  created() {
    this.id = this.$route.query.warrantyId;
    console.log(this.id);
    this.getRepairFrom();
  },
  methods: {
    // 按钮触发的事件
    btnClick() {
      localStorage.setItem("ORDER_INFO", JSON.stringify(this.orderInfo));
      switch (this.orderInfo.status) {
        case 2:
          this.$router.push({ path: "/index/sales/write" });
          break;
        case 3:
          this.$router.push({
            path: "/index/warranty/apply",
            query: {
              type: "modify",
              warrantyId: this.id,
            },
          });
          break;
        case 6:
          this.userReceipt();
          break;
      }
      // if (this.orderInfo.status == 2) {
      //   this.$router.push({ path: "/index/sales/write" });
      // } else {
      //   this.$router.push({
      //     path: "/index/warranty/apply",
      //     query: {
      //       type: "modify",
      //       warrantyId: this.id
      //     },
      //   });
      // }
    },
    // 用户确认收货
    userReceipt() {
      let data = {
        userId: this.userInfo.userId,
        warrantyId: this.orderInfo.warrantyId,
      };
      console.log("data", data);
      this.$request({
        method: "post",
        url: this.$requestPath.userReceipt,
        data,
      })
        .then((res) => {
          // console.log("确认收货", res);
          if (res.code == 0) {
            this.$EventBus.$emit("toast", {
              type: "success",
              message: "操作成功",
            });
            this.getRepairFrom();
          } else {
            this.$EventBus.$emit("toast", { type: "error", message: res.msg });
          }
        })
        .catch((error) => {
          console.log("用户确认收货", error);
        });
    },
    // 跳转到物流页面
    goLogistics(type) {
      localStorage.setItem("ORDER_INFO", JSON.stringify(this.orderInfo));
      this.$router.push({
        path: "/index/sales/orderLogistics?type=" + type,
      });
    },
    // 获取维修详情
    getInfo() {
      // this.$request({
      //   url: this.$requestPath.equipmentDetails,
      //   method: "GET",
      //   params: {
      //     warrantyId: this.id,
      //   },
      // }).then((res) => {
      //   // console.log("获取的维修单详情", res);
      //   if (res.code == 0) {
      //     if (res.data.custom) {
      //       res.data.custom = JSON.parse(res.data.custom);
      //       Object.keys(res.data.custom).forEach((key) => {
      //         res.data[key] = res.data.custom[key];
      //       });
      //     }

      //     this.orderInfo = Object.assign({}, res.data);
      //   } else {
      //     this.$EventBus.$emit("toast", { type: "error", message: res.msg });
      //   }
      // });
      this.$store.dispatch("order/getRepairInfo", {
        warrantyId: this.id,
        callback: (data) => {
          if (data.custom) {
            data.custom = JSON.parse(data.custom);
            Object.keys(data.custom).forEach((key) => {
              data[key] = data.custom[key];
            });
            this.orderInfo = Object.assign({}, data);
          }
        },
      });
    },
    // 获取设备物流表单
    getRepairFrom() {
      this.$store.dispatch("order/getRepairFrom", (data) => {
        this.dataList = [];
        this.dataList.push(...data);
        this.getInfo();
      });
    },
    // 关闭弹窗
    closeSearch() {
      this.isShow = false;
    },
    // 图片预览
    review(url) {
      this.isShow = true;
      this.reviewImg = url;
    },
  },
};
</script>

<style lang="scss" scoped>
table,
td,
th {
  text-align: center;
  border: 1px solid #000;
  border-collapse: collapse;
}

@media screen and (max-width: 640px) {
  .path {
    font-size: 0.875rem;
  }

  .left_td {
    width: 6.25rem;
  }
}

@media screen and (min-width: 640px) {
  .path {
    font-size: 1rem;
  }

  .left_td {
    width: 15.625rem;
  }
}

.tabitem-enter-active,
.tabitem-leave-active {
  transition: all 0.6s ease;
}

.tabitem-enter,
.tabitem-leave-to {
  opacity: 0;
  transform: translateX(0.625rem);
}

.result-enter-active,
.result-leave-active {
  transition: all 0.6s ease;
}

.result-enter,
.result-leave-to {
  opacity: 0;
  transform: translateX(0.625rem);
}
</style>
